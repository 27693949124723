<template>
  <v-card class="ma-4">
    <v-toolbar>
      <v-toolbar-title>ScotsWork Status Tracker</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-autocomplete v-if="isAdmin" v-model="supervisor" :items="supervisorList" label="Supervisor Filter" item-text="name" item-value="pidm" class="ml-2" outlined hide-details dense clearable style="max-width:200px">
        <template v-slot:item="{ item: { email, name, count } }">
          <v-list-item-content>
            <v-list-item-title>{{ name || '**No Supervisor**' }}</v-list-item-title>
            <v-list-item-subtitle>{{ email }}</v-list-item-subtitle>
            <v-list-item-subtitle>{{ count }} student{{ count > 1 ? 's' : ''}}</v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </v-autocomplete>
      <v-select v-if="view === 'timesheet'" v-model="payPeriod" :items="payPeriods" label="Pay Period" return-object outlined hide-details dense style="max-width:200px" class="ml-2"></v-select>
      <v-select v-else-if="view === 'rtw'" v-model="rtwFilter" :items="rtwFilterOptions" label="Ready to Work Filter" outlined hide-details dense style="max-width:200px" class="ml-2"></v-select>
      <v-menu>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" outlined class="ml-2">
            <span>{{ viewOptions.find(({ value }) => value === view).text || 'Work Hour Progress' }}</span>
            <v-icon right>fal fa-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="{ value, text } in viewOptions" :key="value" @click="changeView(value)">
            <v-list-item-title>{{ text }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-menu v-if="user.username === 'jon.moon'">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon>
            <v-icon>fal fa-ellipsis-v</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="doExport()">
            <v-list-item-content>
              <v-list-item-title>Export List</v-list-item-title>
              <v-list-item-subtitle>Export this list to Excel</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <placement-share-dialog>
            <template v-slot:activator="{ on }">
              <v-list-item v-on="on">
                <v-list-item-content>
                  <v-list-item-title>Share List</v-list-item-title>
                  <v-list-item-subtitle>Allow another employee to see your ScotsWork students</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
          </placement-share-dialog>
        </v-list>
      </v-menu>
      <link-menu category="ScotsWork" label="ScotsWork Links" :collapsible-sub-groups="false" />
    </v-toolbar>
    <v-card-text>
      <p style="margin-bottom:0">{{ view === 'work' ? 'Tracking and Hours Remaining ' : 'Timesheet columns (status and hours) ' }} are loaded from Banner and updated hourly.</p>
    </v-card-text>
    <v-data-table :items="filteredStudents" :headers="headers" :loading="loading" @dblclick:row="(e, { item }) => openTimeclock(item)">
      <template v-slot:item.W4="{ item }">
        <v-tooltip v-if="'W4' in item && item.W4" top>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" color="success">fal fa-check-circle</v-icon>
          </template>
          <span>Completed: {{ stringFormatDateToday(item.W4) }}</span>
        </v-tooltip>
        <v-icon v-else-if="'W4' in item" color="error">fal fa-times-circle</v-icon>
      </template>
      <template v-slot:item.DD="{ item }">
        <v-tooltip v-if="'DD' in item && item.DD" top>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" color="success">fal fa-check-circle</v-icon>
          </template>
          <span>Completed: {{ stringFormatDateToday(item.DD) }}</span>
        </v-tooltip>
        <v-icon v-else-if="'DD' in item" color="error">fal fa-times-circle</v-icon>
      </template>
      <template v-slot:item.WB="{ item }">
        <v-tooltip v-if="'WB' in item && item.WB" top>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" color="success">fal fa-check-circle</v-icon>
          </template>
          <span>Completed: {{ stringFormatDateToday(item.WB) }}</span>
        </v-tooltip>
        <v-icon v-else-if="'WB' in item" color="error">fal fa-times-circle</v-icon>
      </template>
      <template v-slot:item.SC="{ item }">
        <v-tooltip v-if="'SC' in item && item.SC" top>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" color="success">fal fa-check-circle</v-icon>
          </template>
          <span>Completed: {{ stringFormatDateToday(item.SC) }}</span>
        </v-tooltip>
        <v-icon v-else-if="'SC' in item" color="error">fal fa-times-circle</v-icon>
      </template>
      <template v-slot:item.SV="{ item }">
        <v-tooltip v-if="'SV' in item && item.SV" top>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" color="success">fal fa-check-circle</v-icon>
          </template>
          <span>Completed: {{ stringFormatDateToday(item.SV) }}</span>
        </v-tooltip>
        <v-icon v-else-if="'SV' in item" color="error">fal fa-times-circle</v-icon>
      </template>
      <template v-slot:item.rtw="{ item }">
        <v-icon :color="item.rtw ? 'success' : 'error'">fas fa-{{ item.rtw ? 'check' : 'times' }}-circle</v-icon>
      </template>
      <template v-slot:item.tracking="{ item }">
        <time-gauge v-if="item.track" :percentComplete="item.track.compPerc" :targetPercent="item.track.targetPerc"></time-gauge>
      </template>
      <template v-slot:item.latest="{ item }">{{ 'clock' in item && 'latest' in item.clock ? stringFormatDateToday(item.clock.latest) : '' }}</template>
      <template v-slot:item.actions="{ item }">
        <v-menu>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon>
              <v-icon>fal fa-ellipsis-v</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item v-if="view === 'work' || view === 'timesheet'" @click="openTimeclock(item)">
              <v-list-item-title v-if="view === 'work'">View Recent Timeclock Entries</v-list-item-title>
              <v-list-item-title v-if="view === 'timesheet'">View Timeclock Entries For This Period</v-list-item-title>
            </v-list-item>
            <v-list-item :to="'/student/schedule/' + item.bannerId">
              <v-list-item-title>View Student's Schedule</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import { computed, onMounted, ref, watch } from '@vue/composition-api'
import { stringFormatDateToday, stringFormatDate } from '@/helpers/formatters'
import { inArray } from '@/helpers/functions'

export default {
  components: {
    TimeGauge: () => import('@/components/work-study/Gauge'),
    PlacementShareDialog: () => import('@/components/work-study/placementShareDialog'),
    LinkMenu: () => import('@/components/system/LinkMenu')
  },
  setup (props, { root }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const isAdmin = computed(() => inArray('Technology Services', user.value.roles) || inArray('Financial Aid Office', user.value.roles) || inArray('Human Resources', user.value.roles))
    const view = computed({
      get: () => root.$store.state['work-study'].adminView,
      set: (val) => root.$store.commit('work-study/setAdminView', val)
    })
    const viewOptions = computed(() => {
      return [
        { value: 'rtw', text: 'Ready-to-Work Checklist' },
        { value: 'work', text: 'Work Hour Progress' },
        { value: 'timesheet', text: 'Timesheet Tracker' }
      ]
    })
    const students = ref([])
    const filteredStudents = computed(() => students.value.filter((row) => {
      if (view.value === 'rtw' && rtwFilter.value !== '') return row.rtw === rtwFilter.value
      else return true
    }))
    const payPeriods = ref([])
    const payPeriod = ref({})
    const rtwFilter = ref('')
    const rtwFilterOptions = ref([
      { text: 'Ready to Work', value: true },
      { text: 'Not Ready to Work', value: false },
      { text: 'Show All', value: '' }
    ])
    const headers = computed(() => {
      const arr = [
        { text: 'Banner ID', value: 'bannerId' },
        { text: 'Name', value: 'name' }
      ]
      switch (view.value) {
        case 'rtw':
          arr.push({ text: 'WorkBright Forms', value: 'WB' })
          arr.push({ text: 'W4 Review', value: 'W4' })
          arr.push({ text: 'Direct Deposit', value: 'DD' })
          arr.push({ text: 'Contract', value: 'SC' })
          arr.push({ text: 'Safety Video', value: 'SV' })
          arr.push({ text: 'Ready to Work?', value: 'rtw' })
          break
        case 'work':
          arr.push({ text: 'Hours/Week', value: 'hours.weekly' })
          arr.push({ text: 'Final Hour Target', value: 'hours.auth' })
          arr.push({ text: 'Hours Remaining', value: 'hours.remaining' })
          arr.push({ text: 'Tracking', value: 'tracking', sortable: false })
          break
        case 'timesheet':
          arr.push({ text: 'Timesheet Status', value: 'tsStatus' })
          arr.push({ text: 'Timesheet Hours', value: 'tsHours' })
          arr.push({ text: 'Timeclock Hours', value: 'clock.hours' })
          arr.push({ text: '# Completed Timeclock Shifts', value: 'clock.completed' })
          arr.push({ text: '# Rejected Timeclock Shifts', value: 'clock.rejected' })
          arr.push({ text: 'Last Clockin', value: 'latest' })
          break
      }
      if (view.value !== 'rtw') arr.push({ text: 'Actions', value: 'actions', sortable: false })
      return arr
    })
    const placements = ref([])
    const hasLast = ref(false)
    const supervisor = ref('')
    watch(supervisor, async (v) => {
      if (v != null && v !== '' && aidy.value !== '') {
        await loadStudents()
        loadData()
      }
    })
    const supervisorList = ref([])
    const aidy = ref('')
    const loading = ref(false)

    onMounted(async () => {
      const dt = new Date()
      let temp = (dt.getFullYear() % 100) * 101 + 1
      if (dt.getMonth() < 6) {
        temp -= 101
      }
      aidy.value = temp + ''
      if (isAdmin.value) {
        const { data: supervisorData } = await root.$feathers.service('work-study/students').find({ query: {
          aidy: aidy.value,
          aggregate: [
            {
              $group: {
                _id: '$supervisor',
                count: { $sum: 1 }
              }
            },
            {
              $sort: {
                '_id.name': 1
              }
            }
          ]
        } })
        supervisorList.value = supervisorData.map(({ _id, count }) => { return { ..._id, count } })
      }
      let year = dt.getFullYear()
      if (dt.getMonth() < 7) year--
      const startDate = new Date(year + '-08-01')
      const { data: payData } = await root.$feathers.service('timeclock/period').find({ query: { start: { $lte: new Date(), $gte: startDate }, $sort: { end: -1 }, $limit: 10 } })
      payPeriods.value = payData.map(({ year, payno, start, end }) => {
        const startDate = stringFormatDate(start, true, true)
        const endDate = stringFormatDate(end, true, true)
        const text = startDate.substring(5) + ' - ' + endDate.substring(5)
        return {
          year,
          payno,
          start,
          end,
          text,
          value: year + '||' + payno
        }
      })
      loadData()
    })

    async function loadStudents () {
      let userPidm = user.value.pidm
      if (supervisor.value) userPidm = supervisor.value
      const aggregate = [
        { $match: { aidy: aidy.value, 'supervisor.pidm': userPidm } },
        { $project: { aidy: 1, pidm: 1, name: 1, bannerId: 1, posn: 1, suff: 1, hours: 1, track: 1 } },
        { $lookup: {
          from: 'WorkStudy-Checklist',
          as: 'checklist',
          let: { pidm: '$pidm', aidy: '$aidy' },
          pipeline: [
            { $match: { $expr: { $and: [{ $eq: ['$pidm', '$$pidm'] }, { $eq: ['$aidy', '$$aidy'] }] } } }
          ]
        } }
      ]
      if (isAdmin.value && (supervisor.value == null || supervisor.value === '')) delete aggregate[0].$match['supervisor.pidm']
      const { data } = await root.$feathers.service('work-study/students').find({ query: { aggregate } })
      students.value = data
    }

    async function loadTimesheets () {
      const { year, payno, start, end } = payPeriod.value
      if (!year || !payno) return
      loading.value = true
      students.value = []
      const aggregate = [
        { $match: { aidy: aidy.value, 'supervisor.pidm': supervisor.value || user.value.pidm } },
        { $unwind: '$timesheets' },
        { $match: { 'timesheets.year': year + '', 'timesheets.period': payno + '' } },
        { $project: { pidm: 1, bannerId: 1, name: 1, posn: 1, suff: 1, tsStatus: '$timesheets.status', tsHours: { $round: ['$timesheets.hours', 2] } } },
        { $lookup: {
          from: 'Timeclock-Punch',
          as: 'timeclock',
          let: { pidm: '$pidm', posn: '$posn', suff: '$suff' },
          pipeline: [
            { $match: { $expr: { $and: [
              { $eq: ['$pidm', '$$pidm'] },
              { $eq: ['$posn', '$$posn'] },
              { $eq: ['$suff', '$$suff'] },
              { $gte: ['$in.date', { $dateFromString: { dateString: start } }] },
              { $lte: ['$in.date', { $dateFromString: { dateString: end } }] }
            ] } } },
            {
              $project: {
                pidm: '$pidm',
                completed: { $cond: [{ $eq: ['$status', 'C'] }, 1, 0] },
                rejected: {
                  $cond: [{ $eq: ['$status', 'R'] }, 1, 0]
                },
                hours: '$hours',
                latest: {
                  $cond: [{ $gt: ['$in.date', '$out.date'] }, '$in.date', '$out.date']
                }
              }
            },
            {
              $group: {
                _id: '$pidm',
                completed: { $sum: '$completed' },
                rejected: { $sum: '$rejected' },
                hours: { $sum: '$hours' },
                latest: { $max: '$latest' }
              }
            }
          ]
        } },
        { $project: { pidm: 1, bannerId: 1, name: 1, posn: 1, suff: 1, tsStatus: 1, tsHours: 1, clock: { $first: '$timeclock' } } },
        { $project: { pidm: 1, bannerId: 1, name: 1, posn: 1, suff: 1, tsStatus: 1, tsHours: 1, clock: { completed: '$clock.completed', rejected: '$clock.rejected', hours: { $round: ['$clock.hours', 2] }, latest: '$clock.latest' } } }
      ]
      const { data: stuData } = await root.$feathers.service('work-study/students').find({ query: { aggregate } })
      students.value = stuData
      loading.value = false
    }

    async function loadData () {
      if (students.value.length === 0) await loadStudents()
      const aggregate = [
        { $match: { aidy: aidy.value, 'supervisor.pidm': supervisor.value || user.value.pidm } }
      ]
      if (isAdmin.value && (supervisor.value == null || supervisor.value === '')) delete aggregate[0].$match['supervisor.pidm']

      switch (view.value) {
        case 'rtw':
          delete aggregate[0].$match['supervisor.pidm']
          aggregate[0].$match.pidm = { $in: students.value.map(({ pidm }) => pidm) }
          const { data: chkData } = await root.$feathers.service('work-study/checklist').find({ query: { aggregate } })
          for (let i = 0; i < students.value.length; i++) {
            const { pidm, bannerId, name, posn, suff } = students.value[i]
            const rec = chkData.find(({ pidm: p }) => p === pidm)
            const temp = { pidm, bannerId, name, posn, suff, rtw: false }
            if (rec) {
              let isCompleted = true
              rec.checklist.forEach(({ code, completed }) => {
                temp[code] = completed
                if (completed == null) {
                  isCompleted = false
                }
              })
              temp.rtw = isCompleted
            }
            students.value.splice(i, 1, temp)
          }
          break
        case 'work':
          aggregate.push({ $project: { aidy: 1, pidm: 1, name: 1, bannerId: 1, posn: 1, suff: 1, hours: 1, track: 1 } })
          const { data: stuData } = await root.$feathers.service('work-study/students').find({ query: { aggregate } })
          students.value = stuData
          break
        case 'timesheet':
          // Look up their Banner timesheet information from the timeclock/job service (using an aggregate to just get the single, previous pay period), and pull in all of their timeclock values for that period
          delete aggregate[0].$match.aidy
          aggregate.push({ $unwind: '$jobs' })
          aggregate.push({ $match: { $expr: { $or: students.value.map(({ pidm, posn, suff }) => { return { pidm, posn, suff } }) } } })
          aggregate.push({ $unwind: '$jobs.timesheets' })
          aggregate.push({ $project: { pidm: 1, posn: '$jobs.posn', suff: '$jobs.suff', ts: '$jobs.timesheets' } })
          aggregate.push({ $match: { 'ts.year': payPeriod.value.year + '', 'ts.period': payPeriod.value.payno + '' } })
          aggregate.push({ $lookup: {
            from: 'Timeclock-Punch',
            as: 'timeclock',
            let: { pidm: '$pidm', posn: '$posn', suff: '$suff', start: '$ts.startDate', end: '$ts.endDate' },
            pipeline: [
              {
                $match: {
                  $expr: {
                    $and: [
                      { $eq: ['$pidm', '$$pidm'] },
                      { $eq: ['$posn', '$$posn'] },
                      { $eq: ['$suff', '$$suff'] },
                      { $gte: ['$in.date', '$$start'] },
                      { $lte: ['$in.date', '$$end'] }
                    ]
                  }
                }
              },
              {
                $project: {
                  pidm: '$pidm',
                  completed: { $cond: [{ $eq: ['$status', 'C'] }, 1, 0] },
                  rejected: {
                    $cond: [{ $eq: ['$status', 'R'] }, 1, 0]
                  },
                  hours: '$hours',
                  latest: {
                    $cond: [{ $gt: ['$in.date', '$out.date'] }, '$in.date', '$out.date']
                  }
                }
              },
              {
                $group: {
                  _id: '$pidm',
                  completed: { $sum: '$completed' },
                  rejected: { $sum: '$rejected' },
                  hours: { $sum: '$hours' },
                  latest: { $max: '$latest' }
                }
              }
            ]
          } })
          aggregate.push({ $project: { pidm: 1, posn: 1, suff: 1, ts: 1, clock: { $first: '$timeclock' } } })
          const { data: timeData } = await root.$feathers.service('timeclock/job').find({ query: { aggregate } })
          for (let i = 0; i < students.value.length; i++) {
            const { pidm, bannerId, name, posn, suff } = students.value[i]
            const rec = timeData.find(({ pidm: p }) => p === pidm)
            const temp = { pidm, bannerId, name, posn, suff, ...rec }
            students.value.splice(i, 1, temp)
          }
          break
      }

      // students.value = []
      // const query = { aidy: aidy.value, 'placement.supervisor.pidm': userPidm, $limit: 0 }
      // const { total } = await root.$feathers.service('work-study/students').find({ query })
      // query.$limit = 20
      // const now = new Date()
      // for (let i = 0; i < total; i += 20) {
      //   query.$skip = i
      //   const { data } = await root.$feathers.service('work-study/students').find({ query })
      //   for (const { pidm, bannerId, name, placement: placementRows } of data) {
      //     const obj = { pidm, bannerId, name, last: { sheet: {}, clock: {} }, current: { sheet: {}, clock: {} } }
      //     for (const { placement, position, suff: suffix, hours, track, supervisor: { pidm: superPidm } } of placementRows) {
      //       if (userPidm === superPidm) {
      //         obj.placement = placement
      //         obj.position = position
      //         obj.suff = suffix
      //         obj.hours = {
      //           auth: hours.auth.toFixed(2),
      //           remaining: hours.remaining.toFixed(2)
      //         }
      //         obj.track = track
      //         // Look up their connected job information to see if there are timesheet entries for the current or previous pay periods (if we have just finished a pay period)
      //         const { data: jobData } = await root.$feathers.service('timeclock/job').find({ query: { pidm } })
      //         if (jobData.length > 0) {
      //           for (const { posn, suff, timesheets } of jobData[0].jobs) {
      //             if (posn === position && (suffix == null || suffix === suff)) {
      //               for (const { hours, startDate, endDate, status } of timesheets) {
      //                 const aggregate = [
      //                   { $match: { pidm, posn: position, 'in.date': { $gte: new Date(startDate), $lte: new Date(endDate) } } },
      //                   { $project: {
      //                     pidm: '$pidm',
      //                     completed: { $cond: [{ $eq: ['$status', 'C'] }, 1, 0] },
      //                     rejected: { $cond: [{ $eq: ['$status', 'R'] }, 1, 0] },
      //                     hours: '$hours',
      //                     latest: { $cond: [{ $gt: ['$in.date', '$out.date'] }, '$in.date', '$out.date'] }
      //                   } },
      //                   { $group: {
      //                     _id: '$pidm',
      //                     completed: { $sum: '$completed' },
      //                     rejected: { $sum: '$rejected' },
      //                     hours: { $sum: '$hours' },
      //                     latest: { $max: '$latest' }
      //                   } }
      //                 ]
      //                 const { data: timeData } = await root.$feathers.service('timeclock/punch').find({ query: { aggregate } })
      //                 if (new Date(endDate) < now) {
      //                   obj.last.sheet.status = status
      //                   obj.last.sheet.hours = hours
      //                   obj.last.startDate = startDate
      //                   obj.last.endDate = endDate
      //                   obj.last.clock = { ...timeData[0], hours: timeData.length > 0 ? timeData[0].hours.toFixed(2) : '' }
      //                   obj.lastLatest = timeData.length > 0 ? timeData[0].latest : ''
      //                   hasLast.value = true
      //                 } else {
      //                   obj.current.sheet.status = status
      //                   obj.current.sheet.hours = hours
      //                   obj.current.startDate = startDate
      //                   obj.current.endDate = endDate
      //                   obj.current.clock = { ...timeData[0], hours: timeData.length > 0 ? timeData[0].hours.toFixed(2) : '' }
      //                   obj.currentLatest = timeData.length > 0 ? timeData[0].latest : ''
      //                 }
      //               }
      //             }
      //           }
      //         }
      //         students.value.push(obj)
      //       }
      //     }
      //   }
      // }
    }

    function changeView (v) {
      view.value = v
      loadData()
    }
    watch(payPeriod, () => {
      if (view.value === 'timesheet') {
        loadTimesheets()
      }
    })

    // When view is work, then we want to include both pay periods in the search
    // We do this by setting the default for startDate to the last pay period, and the default for endDate to the current
    //   (so check to see if the view is current, then set the startDate to the current pay period; otherwise set it to the last pay period - for last and work)
    //   (likewise for endDate, check to see if the view is last then set it to the last pay period; otherwise set it to the current pay period - for current and work)
    function openTimeclock (item) {
      const filter = {
        timeclocks: [],
        student: item.pidm,
        dates: []
      }
      if (view.value === 'timesheet') {
        const { start, end } = payPeriod.value
        filter.dates = [new Date(start), new Date(end)]
      }
      root.$store.commit('work-study/setTimeclockFilter', filter)
      root.$router.push('/scotswork/timeclock/detail')
    }

    function doExport () {
      const arr = filteredStudents.value
      console.log(arr)
    }

    return {
      user,
      isAdmin,
      view,
      viewOptions,
      students,
      filteredStudents,
      payPeriods,
      payPeriod,
      rtwFilter,
      rtwFilterOptions,
      headers,
      placements,
      hasLast,
      supervisor,
      supervisorList,
      loading,
      changeView,
      openTimeclock,
      doExport,
      stringFormatDate,
      stringFormatDateToday
    }
  }
}
</script>
